<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButton
            text="Simular forma de entrega"
            type="primario"
            @click="onSimulation"
          />
          <FormButton
            text="Baixar modelo para frete"
            type="white"
            @click="onDownload"
          />
          <FormButton
            text="Nova forma de entrega"
            type="primario"
            @click="onNew"
          />
        </FormButtonGroup>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        sortBy="nomeforma"
        :sortDesc="false"
        @click="onClick"
        ref="datatable"
        search
      >
        <template v-slot:tipo="{ col }">
          {{ formasEntrega[col] }}
        </template>
        <template v-slot:identrega="{ row }">
          <FormButtonMenu
            icon
            :options="
              row.tipo == 'TRANSPORTE' ? tableOptionsTransp : tableOptions
            "
            :loading="loadingRow[row.idformaentrega]"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import MarketingModules from '@/components/MarketingModules'
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import FormButton from '@/components/form/FormButton'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import {
  FORMA_ENTREGA_REMOVE,
  FORMASENTREGAS
} from '@/store/actions/formasEntrega'

export default {
  name: 'FormaEntregaTable',
  components: {
    MarketingModules,
    DataTable,
    Panel,
    FormButton,
    FormButtonMenu,
    FormButtonGroup
  },
  data: () => ({
    headers: [
      { text: 'Nome', value: 'nomeforma' },
      { text: 'Tipo de entrega', value: 'tipo', align: 'center' },
      { text: 'Última atualização', value: 'dataalteracao', align: 'center' },
      { text: 'Opções', value: 'identrega', clickable: false }
    ],
    tableOptions: [
      { text: 'Deletar', value: 'delete' },
      { text: 'Editar', value: 'update' }
    ],
    tableOptionsTransp: [
      { text: 'Deletar', value: 'delete' },
      { text: 'Editar', value: 'update' },
      { text: 'Detalhes', value: 'details' }
    ],
    formasEntrega: FORMASENTREGAS,
    loadingRow: {},
    modulosForm: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/formas-entrega/${this.getFornecedorId}`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  created() {},
  methods: {
    onClick(item) {
      this.$router.push(`formas-entrega/${item.idformaentrega}`)
    },
    onNew() {
      this.$router.push('formas-entrega/novo')
    },
    onSimulation() {
      this.$router.push('formas-entrega/simulacao')
    },
    onClose() {
      this.$refs.datatable.reload()
      this.loadingRow = {}
    },
    onDownload() {
      const downloadLink =
        'https://s3-sa-east-1.amazonaws.com/prod-superon-public-media/shared/example/importacao-frete-forma-transporte/modelo-importacao-frete-v1.csv'
      window.open(downloadLink, '_blank')
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'delete':
          return this.onDelete(item)
        case 'update':
          return this.onClick(item)
        case 'details':
          return this.onDetail(item)
      }
    },
    onDetail(item) {
      this.$router.replace({
        path: `formas-entrega/${item.idformaentrega}`,
        query: { detalhes: true, nomeforma: item.nomeforma }
      })
    },
    onDelete(item) {
      const options = {
        text: 'Essa ação é irreversível.',
        title: `Você confirma a exclusão da forma de entrega '${item.nomeforma}'?`
      }
      this.confirmSwal(options, () => this.delete(item))
    },
    delete(item) {
      this.loadingRow = {
        ...this.loadingRow,
        [item.idformaentrega]: true
      }
      this.$store
        .dispatch(FORMA_ENTREGA_REMOVE, item)
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Forma de entrega removida com sucesso'
          )
          this.$refs.datatable.reload()
          this.loadingRow = {
            ...this.loadingRow,
            [item.idformaentrega]: false
          }
        })
        .catch(() => {
          this.$vueOnToast.pop('error', 'Erro ao remover a forma de entrega')
          this.loadingRow = {
            ...this.loadingRow,
            [item.idformaentrega]: false
          }
        })
    }
  }
}
</script>
